import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { updateEmailPreferences } from '../api/student'
import SupportTag from './SupportTag';
import FortifyIcon from './FortifyIcon'

const EmailPreferences = () => {
    const [searchParams] = useSearchParams()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [unsubscribed, setUnsubscribed] = useState(false)
    const [showResubscribed, setShowResubscribed] = useState(false)

    const emailTypeMapper = {
        loan_completion_reminder_emails_enabled: 'loan completion reminders',
        loan_counseling_reminder_emails_enabled: 'loan counseling reminders',
        portal_login_reminder_emails_enabled: 'portal login reminders'
    }

    const email = searchParams.get('email')
    const unsubscribeCode = searchParams.get('unsubscribe_code')
    const unsubscribeType = searchParams.get('type')

    const handleToggleSubscription = async (value) => {
        setLoading(true);
        setError(undefined);
        setShowResubscribed(false);
        try {
            const preferences = {
                [unsubscribeType]: value
            }
            await updateEmailPreferences(email, unsubscribeCode, preferences);
            setUnsubscribed(!value);
            if (value) {
                setShowResubscribed(true);
            }
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred. Please try again.');
        }
        setLoading(false);
    }

    if (!email || !unsubscribeCode || !unsubscribeType || !emailTypeMapper[unsubscribeType]) {
        return (
            <div className="application-background">
                <div className="application-container">
                    <FortifyIcon />
                    <div className='text-center'>
                        <p className='font-manrope text-2xl pb-6'><b>Invalid Unsubscribe Link</b></p>
                        <p className='mb-4'>
                            It looks like there was an error with your unsubscribe link. Please reach out to us at{' '}
                            <a 
                                href="mailto:help@fortifyedu.com" 
                                className="underline text-legal-gray hover:text-gray-600"
                            >
                                help@fortifyedu.com
                            </a>
                            {' '}and we will make sure you are unsubscribed.
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="application-background">
            <div className="application-container">
                <FortifyIcon />
                <div className='text-center'>
                    {loading ? (
                        <CircularProgress size={25} />
                    ) : unsubscribed ? (
                        <div className='mb-8'>
                            <p className='font-manrope text-2xl pb-6'>
                                <b>You've been unsubscribed.</b>
                            </p>
                            Sorry to see you go. To get Fortify's reminders again, <br/><button 
                                onClick={() => handleToggleSubscription(true)}
                                className="underline text-blue-600 hover:text-gray-600"
                            >
                                click here to resubscribe
                            </button>.
                        </div>
                    ) : (
                        <div className='mb-8'>
                            <p className='font-manrope text-2xl pb-6'>
                                <b>Want to unsubscribe?</b>
                            </p>
                            {showResubscribed && (
                                <p className='text-green-600 mb-4'>You've been resubscribed!</p>
                            )}
                            To unsubscribe from Fortify's {emailTypeMapper[unsubscribeType]}, <br/><button 
                                onClick={() => handleToggleSubscription(false)}
                                className="underline text-blue-600 hover:text-gray-600"
                            >
                                click here
                            </button>.
                        </div>
                    )}

                    {error && (
                        <p className='text-red-500 mt-4'>{error}</p>
                    )}

                </div>

                <div className="mt-4">
                    <SupportTag />
                </div>
            </div>
        </div>
    )
}

export default EmailPreferences 