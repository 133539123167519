import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TextField, Button, IconButton, InputAdornment, Alert, CircularProgress } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import { login } from '../api/auth';
import FortifyIcon from './FortifyIcon';

import { logErrorToConsole, logErrorToSentryWithContext } from '../utils/errorLogging';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [loginInfo, setLoginInfo] = useState('');
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const redirectParam = queryParams.get('redirectTo');

    if (redirectParam) {
      setRedirectTo(redirectParam);
      setLoginInfo("Please log in to continue.")
    }

    const emailParam = queryParams.get("email");
    
    if (emailParam) {
      setEmail(emailParam);
      setLoginInfo("Looks like you already have an account with Fortify. Please log in to continue.")
    }
  }, [location.search]);

  const isValidEmail = (emailAddress) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(emailAddress);
  };

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      await login(email, password);

      const user = JSON.parse(localStorage.getItem('user'));

      if (user.type !== 'student') {
        window.location.href = 'https://schools.fortifyedu.com';
        return;
      }

      if (redirectTo) {
        navigate(redirectTo);
      } else {
        navigate('/application');
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 401) {
        setLoginError('The email/password was not valid.');
      } else if (error?.response?.status === 429) {
        setLoginError('Sorry, you have made too many attempts. Please contact Fortify support.');
      } else {
        logErrorToConsole("Error logging in", error);
        logErrorToSentryWithContext(error, {
          email,
        });

        setLoginError("Sorry, there was an error logging you in. We're looking into it!");
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="application-background">
      <div className="application-container auth-container">
        <FortifyIcon />

        <div className='w-4/5 text-center'>
          <h1 className="card-title"><b>Login to Fortify</b></h1>
        </div>

        {loginInfo && (
          <Alert severity="info" className='mx-auto text-center' style={{ maxWidth: '400px', marginBottom: '30px', display: 'flex', alignItems: 'center' }}>
            {loginInfo}
          </Alert>
        )}

        <div className="flex flex-col space-y-4 w-full">
          <TextField 
            fullWidth 
            label="Email" 
            variant="outlined" 
            id="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />

          <TextField 
            fullWidth 
            className='sentry-mask'
            label="Password" 
            variant="outlined" 
            id="password" 
            type={showPassword ? 'text' : 'password'} 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className="flex flex-col space-y-4 justify-left">
            {loginError && <Alert severity="error" className='mx-auto text-center' style={{ maxWidth: '400px' }}>{loginError}</Alert>}
            {
              isLoading ? (
                <div className='mt-4 w-full text-center'>
                  <CircularProgress />
                </div>
              ) : (
                <Button
                  variant="contained"
                  className='mt-4'
                  fullWidth
                  sx={{ height: '48px' }}
                  onClick={handleLogin}
                  disabled={!isValidEmail(email) || !password}
                >
                  Login
                </Button>
              )
            }
            <p className='text-sm text-legal-gray text-left'>
              Forgot your password? <a href="/request-password-reset" className="underline">Click here to reset it</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
