import React, { useState, useEffect } from 'react';
import { Modal, Button, Checkbox, FormControlLabel } from '@mui/material';
import { trackDisclosureEvent } from '../api/schools';

const atdsSchoolIds= [
  "9b7ddd00-c1fb-4502-ad9b-14b0ec37d63b",
  "511b757f-0b38-455e-978f-b68de5794537",
  "52b01598-8251-47d0-bd45-73c3f16773ff"
];

const DisclaimerModal = ({ open, requiresDownPayment, onClose, onDecline, onProceed, schoolId, programId }) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (open) {
      trackDisclosureEvent('OPENED', schoolId, programId);
    }
  }, [open, schoolId, programId]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleClose = () => {
    trackDisclosureEvent('CANCELLED', schoolId, programId);
    onClose();
  };

  const handleDecline = () => {
    trackDisclosureEvent('CANCELLED', schoolId, programId);
    onDecline();
  };

  const handleProceed = () => {
    trackDisclosureEvent('AGREED', schoolId, programId);
    onProceed();
  };

  const paymentStartMessage = atdsSchoolIds.includes(schoolId)
    ? "Your monthly payments will start 30 days after classes end."
    : "Your monthly payments will start 30 days after classes begin.";

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="disclaimer-modal-title"
      aria-describedby="disclaimer-modal-description"
    >
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'calc(100% - 32px)',
        maxWidth: '400px',
        backgroundColor: '#fff',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        padding: '32px',
        borderRadius: '8px',
        maxHeight: '90vh',
        overflowY: 'auto'
      }}>
        <h1 className="font-manrope text-2xl pb-6" style={{ fontFamily: 'Manrope', fontWeight: 600 }}>
          Are you ready to apply?
        </h1>
        <div id="disclaimer-modal-description" style={{ marginBottom: '16px' }}>
          <p className="mb-4">Your education is a valuable investment, and it comes with financial responsibility. If you take out a Fortify loan for school, you'll be responsible for repaying it.</p>
          <p className="mb-4"><b>Here's what you need to know:</b></p>
          <ul className="space-y-1" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
            <li>You {requiresDownPayment ? "will" : "may"} have to make a down payment.</li>
            <li>{paymentStartMessage}</li>
            <li>Fortify is a private loan and it won't be eligible for federal loan forgiveness programs.</li>
          </ul>
        </div>
        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
          label="I understand my responsibilities, and I'm ready to make payments if approved."
        />
        <div style={{ marginTop: '16px', display: 'flex', flexDirection: 'column' }}>
          <Button
            variant="outlined"
            onClick={handleDecline}
            style={{ marginBottom: '8px' }}
          >
            Let me think about it
          </Button>
          <Button
            variant="contained"
            onClick={handleProceed}
            disabled={!isChecked}
          >
            Start my application
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DisclaimerModal;