import React, { useState, useEffect } from 'react';
import { TextField, Button, IconButton, InputAdornment, Alert, CircularProgress } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate, useLocation } from 'react-router-dom';
import { resetPassword } from '../api/auth';
import FortifyIcon from './FortifyIcon';

import { logErrorToConsole, logErrorToSentryWithContext } from '../utils/errorLogging';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [passwordResetCode, setPasswordResetCode] = useState('');

  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');

  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const email = searchParams.get('email');

    if (!code || !email) {
      navigate('/request-password-reset');
    }

    setPasswordResetCode(code);
    setEmail(email);
  }, [location.search, navigate]);

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (validateForm()) {
      try {
        await resetPassword(email, passwordResetCode, newPassword);

        const user = JSON.parse(localStorage.getItem('user'));

        if (user.type !== 'student') {
          window.location.href = 'https://schools.fortifyedu.com';
          return;
        }

        navigate('/application');
      } catch (error) {
        logErrorToConsole("Error resetting password", error);
        logErrorToSentryWithContext(error, {
          email,
        });

        setApiError(error?.response?.data?.error || "There was an error resetting your password. We're looking into it!");
      }
    }
    setIsLoading(false);
  };

  const validateForm = () => {
    let tempErrors = {};

    if (newPassword.length < 8) tempErrors.newPassword = "Your password must be at least 8 characters.";
    if (newPassword !== confirmNewPassword) tempErrors.confirmNewPassword = "Your passwords do not match.";

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="application-background">
      <div className="application-container auth-container">
        <FortifyIcon />

        <div className='sm:w-4/5 text-center'>
          <h1 className="text-2xl mb-4 font-manrope"><b>Reset your password</b></h1>
          {<p className="mb-8">Please choose a new password that's at least 8 characters long.</p>}
        </div>

        <div>
          <div className="space-y-4">
            <TextField
              fullWidth
              className='sentry-mask'
              label="New Password"
              variant="outlined"
              id="newPassword"
              type={showPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              error={!!errors.newPassword}
              helperText={errors.newPassword || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              className='sentry-mask'
              label="Confirm Password"
              variant="outlined"
              id="confirmNewPassword"
              type={showPassword ? 'text' : 'password'}
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              error={!!errors.confirmNewPassword}
              helperText={errors.confirmNewPassword || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {apiError && <Alert severity="error" className="mt-4 text-center" style={{ maxWidth: '400px' }}>{apiError}</Alert>}
          <div className="mt-8 w-full text-center">
            {
              isLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSubmit}
                  disabled={!newPassword || !confirmNewPassword}
                  sx={{ height: '48px' }}
                >
                  Submit
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;