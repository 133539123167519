import React, { useEffect, useState } from 'react';
import { triggerEmailConfirmation } from '../../api/auth';
import { Alert } from '@mui/material';
import FortifyIcon from '../FortifyIcon';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const PendingEmailVerification = ({ applicationData }) => {
  const [emailResent, setEmailResent] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const applicationId = applicationData?.id;

    if (applicationId) {
      triggerEmailConfirmation(applicationId)
        .catch((error) => {
          if (!error?.response?.status || error?.response?.status !== 400) {
            logErrorToConsole("Failed to trigger email confirmation", error);
            logErrorToSentryWithContext(error, {
              applicationId,
            });

            setError("There was an internal error. Our team has been notified and we're looking into it!");
          } else {
            setError("You've triggered too many emails. Please contact us if you haven't received an email yet.");
          }
        });
    }
  }, [applicationData]);

  const handleResendEmail = () => {
    setEmailResent(false);
    setError('');

    const applicationId = applicationData?.id;

    if (applicationId) {
      triggerEmailConfirmation(applicationId)
        .then(() => setEmailResent(true))
        .catch((error) => {
          if (!error?.response?.status || error?.response?.status !== 400) {
            logErrorToConsole("Failed to trigger email confirmation", error);
            logErrorToSentryWithContext(error, {
              applicationId,
            });

            setError("There was an internal error. Our team has been notified and we're looking into it!");
          } else {
            setError("You've triggered too many emails. Please contact us if you haven't received an email yet.");
          }
        });
    }
  };

  return (
    <>
      <FortifyIcon />

      <div className='w-4/5 text-center'>
        <h1 className="card-title"><b>Verify your email</b></h1>
        <div className='space-y-4'>
          <p>Please click the link in your email to continue.</p>
          <p className='text-xs text-legal-gray'>Don't see it? Make sure to check your Spam and Trash folders. Or, <a className='underline' href="#" onClick={(event) => { event.preventDefault(); handleResendEmail(); }}>resend the email</a>.</p>
        </div>
        {emailResent && <Alert severity="success" className="mt-4" style={{ maxWidth: '400px', textAlign: 'center' }}>Email resent! Please check your inbox.</Alert>}
        {error && <Alert severity="error" className="mt-4" style={{ maxWidth: '400px', textAlign: 'center' }}>{error}</Alert>}
      </div>
    </>
  )
};

export default PendingEmailVerification;
